import * as z from "zod";

export const slideContentTypeEnum = z.enum([
    "title",
    "subtitle",
    "strong",
    "emphasis",
    "note",
    "point",
    "quote",
    "text",
    "image",
    "screenshare"
]);

export type SlideContentType = z.infer<typeof slideContentTypeEnum>;

export const slideContentOption = z.string();

export const slideContentSchema = z.object({
    text: z.string(),
    type: slideContentTypeEnum,
    options: z.array(slideContentOption)
});

export type SlideContent = z.infer<typeof slideContentSchema>;

export const moodSchema = z.object({
    topColor: z.string(),
    bottomColor: z.string(),
    sideColor: z.string(),
})

export type Mood = z.infer<typeof moodSchema>;

export const slideStandingPositioningEnum = z.enum([
    "standAbove",
    "standBelow"
]); 

export const slideLayingPositioningEnum = z.enum([
    "layAbove",
    "layBelow"
]); 

export const slidePositioningEnum = z.union([slideStandingPositioningEnum, slideLayingPositioningEnum]);

export type SlidePositioning = z.infer<typeof slidePositioningEnum>;

export const layingTransitionEnum = z.enum([
    "none",
    "travLeft",
    "travRight",
    "travUp",
    "travDown"
]);

export type LayingTransition = z.infer<typeof layingTransitionEnum>;


export const standingTransitionEnum = z.enum([
    "none",
    "travLeft",
    "travRight",
    "travForward",
    "travBackward"
]);

export type StandingTransition = z.infer<typeof standingTransitionEnum>;

const abstractSlideSchema = z.object({
    mood: moodSchema,
    contents: z.array(z.array(slideContentSchema))
});

export const standingPositioningSlideSchema = abstractSlideSchema.merge(z.object({
    positioning: slideStandingPositioningEnum,
    inTransition: standingTransitionEnum,
    outTransition: standingTransitionEnum,
}));

export const layingPositioningSlideSchema = abstractSlideSchema.merge(z.object({
    positioning: slideLayingPositioningEnum,
    inTransition: layingTransitionEnum,
    outTransition: layingTransitionEnum,
}));

export const slideSchema = z.union([standingPositioningSlideSchema, layingPositioningSlideSchema]);

export type Slide = z.infer<typeof slideSchema>;