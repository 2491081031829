import React from 'react';
import { useTransition } from "react-spring";
import useSlideStore from '../../hooks/useSlideStore';
import useCameraStore from '../../hooks/useCameraStore';
import getFramePositionAndRotationFromPositioning from '../../helpers/getFramePositionAndRotationFromPositioning';
import Frame from "./Frame";


const Frames = () => {
    const { getCurrentSlide} = useSlideStore(({ getCurrentSlide, currentIndex}) => ({getCurrentSlide}));
    const { orientation, deltaPosition } = useCameraStore(({ deltaPosition, orientation }) => ({ deltaPosition, orientation }));

    const currentSlide = getCurrentSlide();
    const {position, rotation } = getFramePositionAndRotationFromPositioning({positioning: currentSlide.positioning, orientation})
    const enhancedCurrentSlide = {
        ...currentSlide,
        position: position.clone().add(deltaPosition),
        rotation
    }

    const transitions = useTransition([enhancedCurrentSlide], {
        keys: item => item.index,
        // the following params are not used but allow to control the mount/unmount cycle of each frames
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration:1000
        }
    });

    return transitions((params, item) => {
        return (<Frame slide={item} />);
    });
}

export default Frames;