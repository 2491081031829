import moize from "moize";
import { Color } from "three";

const DEFAULT_COLOR = "white";

const getThreeColor = (color?: string) => {
    const threeColor = new Color(color || DEFAULT_COLOR);
    threeColor.convertSRGBToLinear();
    return threeColor;
}

export default moize(getThreeColor);