import create, { State } from "zustand";
import { Slide } from "../components/Slide/schemas";
import getDeltaPositionAccordingToSlideTransition from "../helpers/getDeltaPositionAccordingToSlideTransition";
import getOrientationFromSlidesTransition, { sumAngle } from "../helpers/getOrientationFromSlideTransition";
import useCameraStore from "./useCameraStore";

const blankSlide: Slide = {
    mood: {
        topColor: "#000",
        bottomColor: "#000",
        sideColor: "#000"
    },
    positioning: "standAbove",
    inTransition: "none",
    outTransition: "none",
    contents: [[]]
}

interface SlideState extends State {
    currentIndex: number;
    currentKey: number;
    slides: Slide[]
    setSlides: (slides: Slide[]) => void;
    getCurrentSlide: () => Slide & {index: number};
    setCurrentSlide: (index: number, key: number) => void;
    getSlidesCount: () => number;
}

const useSlideStore = create<SlideState>((set, get) => ({
    currentIndex: -1,
    currentKey: 0,
    slides: [],
    setSlides: (slides) => {set({slides})},
    getCurrentSlide: () => {
        const slides = get().slides;
        const currentIndex = get().currentIndex;
        const currentSlide = slides[currentIndex] || blankSlide;
        return {
            index: currentIndex,
            ...currentSlide
        };
    },
    setCurrentSlide: (currentIndex, currentKey) => {
        // get previously current slide (not previous position in the array)
        const slides = get().slides;
        const previousIndex = get().currentIndex;
        const previousSlide = slides[previousIndex] || blankSlide;
        // set new current slide
        set({currentIndex, currentKey});
        const currentSlide = slides[currentIndex] || blankSlide;

        if(previousIndex !== currentIndex) {
            const reverse = currentIndex < previousIndex;
    
            // update camera orientation
            
            const deltaPosition = getDeltaPositionAccordingToSlideTransition(
                previousSlide.positioning,
                reverse ? previousSlide.inTransition : previousSlide.outTransition,
                useCameraStore.getState().orientation,
                reverse
            )
            const onGoingDeltaPosition = useCameraStore.getState().deltaPosition;
            const currentRotation = useCameraStore.getState().orientation;
    
            const orientation = getOrientationFromSlidesTransition(
                reverse ? previousSlide.inTransition : previousSlide.outTransition, 
                reverse ? currentSlide.outTransition : currentSlide.inTransition,
                previousSlide.positioning,
                currentSlide.positioning,
                reverse
            );
    
            useCameraStore.setState({ orientation: sumAngle(currentRotation, orientation), deltaPosition: onGoingDeltaPosition.clone().add(deltaPosition) });    
        }
    },
    getSlidesCount: () => get().slides.length
}))

export default useSlideStore;