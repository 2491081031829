import React, { useEffect, useMemo, useState } from 'react';
import { useFrame } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import { useSpring, useSpringRef } from "@react-spring/core";
import getCameraRelativePositionAndRotation from '../../helpers/getCameraRelativePosition';
import { Euler, Vector3 } from 'three';
import deepEqual from 'deep-equal';
import useSlideStore from '../../hooks/useSlideStore';
import useCameraStore from '../../hooks/useCameraStore';

const vector3ToObject = ({x, y, z}: Vector3 | Euler) => ({x, y, z});
const vector3ToArray = ({x, y, z}: Vector3 | Euler) => ([x, y, z]);

const initPosition = new Vector3();
const initRotation = new Euler();

const Camera = () => {
    const { getCurrentSlide } = useSlideStore(({ getCurrentSlide }) => ({ getCurrentSlide }));
    const { deltaPosition, orientation, resetDeltaPosition } = useCameraStore(({ deltaPosition, orientation, resetDeltaPosition }) => ({ deltaPosition, orientation, resetDeltaPosition }));
    const { positioning } = getCurrentSlide();

    const { position: relativePosition, rotation } = useMemo(() =>  getCameraRelativePositionAndRotation({ positioning, orientation }), [positioning, orientation]);


    const position = vector3ToObject(deltaPosition.clone().add(relativePosition));

    const springPositionRef = useSpringRef();

    const positionSpring = useSpring({ 
      ref: springPositionRef,
      config: {
        mass: 19,
        tension: 17,
        friction: 31,
        velocity: 0,
        precision: 0.0001
      },
      to: position,
      onRest: () => {
        springPositionRef.set(vector3ToObject(relativePosition));
        resetDeltaPosition();
      }
    });

    const rotationSpring = useSpring({ ...rotation, config: {
      mass: 19,
      tension: 17,
      friction: 31,
      velocity: 0,
      precision: 0.0001
    }});

    const [currentPosition, setCurrentPosition] = useState(initPosition);
    const [currentRotation, setCurrentRotation] = useState(initRotation);
   
    useFrame(() => {
      const nextPosition = [
        positionSpring.x.get(), 
        positionSpring.y.get(), 
        positionSpring.z.get()
      ];
      if(!deepEqual(nextPosition, vector3ToArray(currentPosition))) {
        setCurrentPosition(new Vector3(...nextPosition));
      }
      const nextRotation = [
        rotationSpring.x.get(), 
        rotationSpring.y.get(), 
        rotationSpring.z.get()
      ];
      if(!deepEqual(nextRotation, vector3ToArray(currentRotation))) {
        setCurrentRotation(new Euler(...nextRotation));
      }
    });

    useEffect(() => {
      springPositionRef.start();
    }, [deltaPosition]);

    return (
    <>
      <PerspectiveCamera makeDefault rotation={currentRotation} position={currentPosition} />
    </>);
}

export default Camera;