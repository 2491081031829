import * as z from "zod";
import { find } from "remeda";
import { JustifyContent } from "@react-three/flex";
import { SlideContent } from "../../components/Slide/schemas";

const textAlignEnum = z.enum([
    "center",
    "left",
    "right",
    "justify"
])

type TextAlign = z.infer<typeof textAlignEnum>;

interface AlignmentRules {
    boxAlign: JustifyContent;
    textAlign: TextAlign;
}
const alignmentRulesByOption: Record<TextAlign, AlignmentRules> = {
    "left": {
        textAlign: "left",
        boxAlign: "flex-start",
    },
    "center": {
        textAlign: "center",
        boxAlign: "center",
    },
    "right": {
        textAlign: "right",
        boxAlign: "flex-end",
    },
    "justify": {
        textAlign: "justify",
        boxAlign: "flex-start",
    },
};

const getAlignmentRulesFromOptions = (options: SlideContent["options"]): AlignmentRules => {
    const alignmentOption = find(options, option => textAlignEnum.check(option)) as TextAlign || 'center' ;
    return alignmentRulesByOption[alignmentOption];
}

export default getAlignmentRulesFromOptions;
