import { Mood } from "../Slide/schemas";

const createSkyBoxEquirectangle = ({
    topColor,
    bottomColor,
    sideColor
}: Mood) => `<svg width="64" height="32" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <defs>
      <linearGradient 
        id="LinearGradient" 
        x1="0"
        x2="0"
        y1="0"
        y2="1"
        >
        <stop offset="30%" stop-color="${topColor}"/>
        <stop offset="70%" stop-color="${bottomColor}"/>
      </linearGradient>
      <linearGradient 
        id="LinearGradientBounds" 
        x1="0"
        x2="0"
        y1="0"
        y2="1"
        >
        <stop offset="0%" stop-color="${topColor}"/>
        <stop offset="20%" stop-color="${topColor}" stop-opacity="0"/>
        <stop offset="80%" stop-color="${bottomColor}" stop-opacity="0"/>
        <stop offset="100%" stop-color="${bottomColor}"/>
      </linearGradient>
      <radialGradient 
        id="RadialGradient" 
        cx="0.5"
        cy="0.4"
        r="0.3"
        >
        <stop offset="0%" stop-color="${sideColor}"/>
        <stop offset="100%" stop-color="black" stop-opacity="0"/>
      </radialGradient>
  </defs>
  <rect x="0" y="0" width="64" height="32" fill="url(#LinearGradient)" />
  <rect x="0" y="-16" width="64" height="64" fill="url(#RadialGradient)" />
  <rect x="0" y="0" width="64" height="32" fill="url(#LinearGradientBounds)" />
</svg>`;

export default createSkyBoxEquirectangle;