import React from "react";
import { resolveScreenShareTexture } from "../Slide/ScreenShare";

const ShareScreen = () => {
    const handleScreenShare = () => {
        resolveScreenShareTexture().then((videoTexture: any) => {
            console.log("video texture resolved !");
        })
    }
    return <button onClick={handleScreenShare}>ShareScreen</button>;
}

export default ShareScreen;