import { useCallback, useEffect, useRef } from "react";
import chroma, { Scale } from "chroma-js";
import moize from "moize";
import { useThree, useFrame } from "@react-three/fiber";
import { TextureLoader, Texture, WebGLCubeRenderTarget, sRGBEncoding, NoToneMapping } from "three";
import Ola from "ola";


import createSkyBoxEquirectangle from "./createSkyBoxEquirectangle";
import { pipe, map, prop } from 'remeda';
import useSlideStore from "../../hooks/useSlideStore";
import { Mood } from "../Slide/schemas";

const loader = new TextureLoader();

const getSkyBoxEquirectangleUrl = ({ topColor, bottomColor, sideColor }: Mood) =>
  `data:image/svg+xml,${encodeURIComponent(
    createSkyBoxEquirectangle({ topColor, bottomColor, sideColor })
  )}`;


const getSkyboxTexture = moize({
  isPromise: true,
  maxSize: 100,
})((topColor: string = "#000", bottomColor: string = "#000", sideColor: string = "#000") =>
  loader.loadAsync(getSkyBoxEquirectangleUrl({ topColor, bottomColor, sideColor }))
);

const getScaleFromMoodsQueue = moize({
  maxSize: 20,
})((moodsQueue: Mood[], colorName: keyof Mood): Scale => pipe(moodsQueue, map(prop(colorName)), (colorValues) => chroma.scale(colorValues).mode("hsl").domain([0, colorValues.length - 1])));

const getHexColorFromScale = moize({
  maxSize: 100,
})((scale: Scale, v: number) => scale(v).hex());


enum AnimationFrameRate {
  Full = 1,
  Half = 2,
  Third = 3,
  Quater = 4,
}

// Loads the skybox texture and applies it to the scene.
function SkyBox() {
  const { getCurrentSlide } = useSlideStore(({getCurrentSlide}) => ({ getCurrentSlide}));
  const mood = getCurrentSlide().mood;
  const { scene, gl } = useThree();
  const frameRef = useRef(0);
  const moodsQueueRef = useRef<Mood[]>([]);
  const shadeRef = useRef(Ola(0)); 
  const renderTargetRef = useRef<null | WebGLCubeRenderTarget>(null);

  const reset = moize((cacheDiscriminatingParameter) => {
    moodsQueueRef.current = [moodsQueueRef.current[moodsQueueRef.current.length - 1]];
    shadeRef.current.set(0, 0.0001);
  });
  

  const handleTextureLoad = useCallback(moize((texture: Texture) => {
    texture.encoding = sRGBEncoding;
    if (renderTargetRef.current) {
      renderTargetRef.current.fromEquirectangularTexture(gl, texture);
    }
  }), [gl])


  useEffect(() => {
    gl.outputEncoding = sRGBEncoding;
    gl.toneMapping = NoToneMapping;
    if (renderTargetRef.current === null) {
      getSkyboxTexture().then((texture) => {
        renderTargetRef.current = new WebGLCubeRenderTarget(texture.image.height);
        scene.background = renderTargetRef.current.texture;
      });
    }
  }, []);

  useEffect(() => {
    // on mood change, push new color in scales (+ update domain)
    if(shadeRef.current.value < moodsQueueRef.current.length - 2){
      // replace last mood in queue
      const updatedMoodsQueue = [...moodsQueueRef.current];
      updatedMoodsQueue[moodsQueueRef.current.length - 1] = mood;
      moodsQueueRef.current = updatedMoodsQueue;
    }
    else {
      // add new mood in queue
      moodsQueueRef.current = [...moodsQueueRef.current, mood];
    }
    shadeRef.current.set(moodsQueueRef.current.length - 1, 2000);
  }, [mood])

  useFrame(() => {
    if (frameRef.current === 0) {
      const topColorScale = getScaleFromMoodsQueue(moodsQueueRef.current, "topColor"); 
      const bottomColorScale = getScaleFromMoodsQueue(moodsQueueRef.current, "bottomColor"); 
      const sideColorScale = getScaleFromMoodsQueue(moodsQueueRef.current, "sideColor");

      const topColor = getHexColorFromScale(topColorScale, shadeRef.current.value);
      const bottomColor = getHexColorFromScale(bottomColorScale, shadeRef.current.value);
      const sideColor = getHexColorFromScale(sideColorScale, shadeRef.current.value);

      getSkyboxTexture(topColor, bottomColor, sideColor).then(handleTextureLoad);
    }
    // update frame count
    frameRef.current = (frameRef.current + 1) % AnimationFrameRate.Third;


    // if mood transition completed, reset queue and shade
    if(shadeRef.current.value === moodsQueueRef.current.length - 1) {
      reset(shadeRef.current.value);
    }
  });

  return null;
}

export default SkyBox;
