import React, { useEffect } from "react";
import { Canvas } from "@react-three/fiber";

import KeyboardNavigation from "./components/KeyboardNavigation/KeyboardNavigation";
import SkyBox from "./components/SkyBox/SkyBox";
import Grid from "./components/Grid/Grid";
import Camera from "./components/Camera/Camera";
import Frames from "./components/Frame/Frames";
import SlideNumber from "./components/SlideNumber/SlideNumber";
import useSlideStore from "./hooks/useSlideStore";
import { SocketContext, socket } from "./components/shared/contexts";
import isOffline from "./helpers/env/isOffline";
import ShareScreen from "./components/ShareScreen/ShareScreen";

function App() {
  const { setSlides } = useSlideStore(({ setSlides })=> ({ setSlides }))

  useEffect(() => {
    if(isOffline()) {
      console.log("offline")
      const loadDocument = require("./document/loadDocument").default;
      const slides = loadDocument();
      setSlides(slides);
    }
    else {
      socket.on("connect", () => {
        if(process.env.REACT_APP_BROADCASTER === "true") {
          const loadDocument = require("./document/loadDocument").default;
          const slides = loadDocument();
          socket.emit("loadSlides", slides);
        }
      });
      socket.on("refreshSlides", (slides) => {
        setSlides(slides);
      });
    }
  }, [])

  return (
    <SocketContext.Provider value={socket}>
      <KeyboardNavigation />
      <Canvas className="canvas">
        <Camera />
        <SkyBox />
        <Grid />
        <Frames />
      </Canvas>
      <SlideNumber />
      <ShareScreen />
    </SocketContext.Provider>
  );
}

export default App;