/* import Peer from "peerjs";

const peerOptions = {
    host: "SERVER HOST",
    port: "SERVER PORT",
    path: "peer/",
    secure: true
}

// presenter
{
const peer = new Peer('presenter-peer-id', peerOptions);

navigator.mediaDevices.getDisplayMedia({video: true, audio: false}, function(stream) {
  var call = peer.call('spectator-peers-id', stream);
  
}, function(err) {
  console.log('Failed to get local stream' ,err);
});
}


// spectator
const peer = new Peer('presenter-peer-id', peerOptions);

peer.on('call', function(call) {
    call.answer(); // answer without providing a mediastream
    call.on("stream", function(remoteStream) {
        // TODO: display stream in video/canvas
    });
})*/

import React, { memo, useContext, useState } from 'react';
import { animated } from 'react-spring/three';
import { Box } from '@react-three/flex';
import { Plane } from '@react-three/drei';
import { FRAME_SIZE, FRAME_WIDTH } from '../Frame/constants';
import { DoubleSide, sRGBEncoding, VideoTexture } from 'three';
import { OpacityContext } from '../shared/contexts';
import { ImageSize, IMAGE_SIZE, imageSizeEnum } from '../ImageBox/constants';
import { SlideContent } from '../Slide/schemas';
import getAlignmentRulesFromOptions from '../../document/helpers/getAlignmentRulesFromOptions';
import { useFrame } from '@react-three/fiber';

interface Props {
    children?: VideoTexture;
    options: SlideContent["options"];
}

const VideoBox = ({ children: texture, options }: Props) => {
    const [width, setWidth ] = useState(0);
    const [height, setHeight ] = useState(0);

    const opacity = useContext(OpacityContext);

    useFrame(() => {
        if(texture) {
            const nextWidth = Math.min(texture.image.videoWidth/texture.image.videoHeight, 1) * size;
            const nextHeight = Math.min(texture.image.videoHeight/texture.image.videoWidth, 1) * size;
            if(nextWidth !== width) {
                setWidth(nextWidth);
            }
            if(nextHeight !== height) {
                setHeight(nextHeight);
            }
        }
        
    });
    
    if(!texture) {
        return null;
    }

    texture.encoding = sRGBEncoding;
    const sizeOption: ImageSize = options.find((option) => imageSizeEnum.check(option)) as ImageSize || "half";
    const size = IMAGE_SIZE[sizeOption];
    const alignmentRules = getAlignmentRulesFromOptions(options);


    console.log(texture.image, width, height);

    return (<Box width={FRAME_WIDTH} height={height} marginTop={FRAME_SIZE/40} marginBottom={FRAME_SIZE/40} justifyContent={alignmentRules.boxAlign} flexDirection="row" flexWrap="no-wrap">
        <Box width={width} height={height} centerAnchor>
            <Plane args={[width, height]}>
                <animated.meshBasicMaterial map={texture} side={DoubleSide} opacity={opacity} transparent></animated.meshBasicMaterial>
            </Plane>
        </Box>
    </Box>)
};

export default memo(VideoBox);