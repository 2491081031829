import * as z from "zod";
import { layingTransitionEnum, moodSchema, slideLayingPositioningEnum, slideStandingPositioningEnum, standingTransitionEnum } from "../components/Slide/schemas";


const abstractDocumentSlideSchema = z.object({
    mood: moodSchema,
    contents: z.union([z.string(), z.array(z.string())]),
});

const standingPositioningDocumentSlideSchema = abstractDocumentSlideSchema.merge(
    z.object({
        positioning: slideStandingPositioningEnum,
        transition: z.union([
            standingTransitionEnum,
            z.tuple([standingTransitionEnum, standingTransitionEnum])
        ])
    })
)

const layingPositioningDocumentSlideSchema = abstractDocumentSlideSchema.merge(
    z.object({
        positioning: slideLayingPositioningEnum,
        transition: z.union([
            layingTransitionEnum,
            z.tuple([layingTransitionEnum, layingTransitionEnum])
        ])
    })
)

const documentSlideSchema = z.union([standingPositioningDocumentSlideSchema, layingPositioningDocumentSlideSchema]);

export type DocumentSlide = z.infer<typeof documentSlideSchema>;

export const documentSchema = z.object({
    slides: z.array(documentSlideSchema)
})
// allow extra keys (yaml templates)
.nonstrict()