import { Vector3, Euler } from "three";
import { FRAME_HEIGHT } from "../components/Frame/constants";
import { SlidePositioning } from "../components/Slide/schemas";
import { SCENE_SCALE } from "../constants";
import { sumAngle } from "./getOrientationFromSlideTransition";

interface Arg1 {
    positioning: SlidePositioning;
    orientation: number;
}

const getFramePositionAndRotationFromPositioning = ({ positioning, orientation}: Arg1) => {
    if(positioning === "standAbove") {
        return {
            position: new Vector3(0, FRAME_HEIGHT / 2, -1),
            rotation: new Euler(0, orientation, 0)
        }
    }
    if(positioning === "standBelow") {
        return {
            position: new Vector3(0, -FRAME_HEIGHT/2, -1),
            rotation: new Euler(Math.PI, -sumAngle(orientation, Math.PI), 0)
        }
    }
    if(positioning === "layAbove") {
        return {
            position: new Vector3(0, 0.1 * SCENE_SCALE, 0),
            rotation: new Euler(-Math.PI/2, 0, orientation)
        }
    }
    return {
        position: new Vector3(0, -0.1 * SCENE_SCALE, 0),
        rotation: new Euler(-Math.PI/2, Math.PI, -sumAngle(orientation, 0))
    }
}

export default getFramePositionAndRotationFromPositioning;