import React from "react";

import { DoubleSide, TextureLoader, RepeatWrapping, Vector2, Color } from "three";
import { GRID_SIZE, TEXTURE_REPEAT } from "./constants";
import createGrid from "./createGrid";

const loader = new TextureLoader();

const getGridUrl = () => `data:image/svg+xml,${encodeURIComponent(createGrid())}`;
const getGridTexture = () => loader.load(getGridUrl());

const Grid = () => {
    const texture = getGridTexture();
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.repeat = new Vector2(TEXTURE_REPEAT,TEXTURE_REPEAT);
    texture.anisotropy = 4;

    return (
        <mesh rotation={[-Math.PI/2,0,0]}>
        <planeGeometry args={[GRID_SIZE, GRID_SIZE]} />
        <meshPhongMaterial map={texture} side={DoubleSide} transparent emissive={new Color("white")} opacity={0.8}  />
      </mesh>)
}

export default Grid;