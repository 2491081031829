import { useContext, useEffect, useRef } from 'react';
import isOffline from '../../helpers/env/isOffline';
import useSlideStore from '../../hooks/useSlideStore';
import { SocketContext } from '../shared/contexts';

const KeyboardNavigation = () => {
    const { getCurrentSlide, setCurrentSlide, getSlidesCount } = useSlideStore(({ getCurrentSlide, setCurrentSlide, getSlidesCount })=> ({getCurrentSlide, setCurrentSlide, getSlidesCount}))

    const socket = useContext(SocketContext);

    useEffect(() => {
        if(!isOffline()) {
            socket.on("refreshCurrentSlide", (slideIndex: number, key: number) => {
                setCurrentSlide(slideIndex, key)
            });
        }
    
    }, []);


    const loadCurrentSlide = (slideIndex: number, key: number) => {
        if(isOffline()) {
            setCurrentSlide(slideIndex, key);
        }
        else {
            socket.emit("loadCurrentSlide", slideIndex, key);
        }
    }


    const controlKey = useRef(false);
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.code === "ControlLeft" || e.code === "ControlRight") {
            controlKey.current = true;
        }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
        if (e.code === "ControlLeft" || e.code === "ControlRight") {
            controlKey.current = false;
        }

        const currentSlide = getCurrentSlide();
        const currentIndex = currentSlide.index;
        const keysCount = currentSlide.contents.length;  
        const slidesCount = getSlidesCount(); 
        const currentKey = useSlideStore.getState().currentKey;
        
        
        if(e.code === "ArrowRight" ) {
            if(currentKey < keysCount - 1) {
                if(controlKey.current) {
                    loadCurrentSlide(currentIndex, keysCount - 1);
                }
                else {
                    loadCurrentSlide(currentIndex, currentKey + 1);
                }
            }
            else if (currentIndex < slidesCount - 1) {
                if(controlKey.current) {
                    const nextSlide = useSlideStore.getState().slides[currentIndex + 1];
                    loadCurrentSlide(currentIndex + 1, nextSlide.contents.length - 1);
                }
                else {
                    loadCurrentSlide(currentIndex + 1, 0);
                }
            }
        }
        if(e.code === "ArrowLeft") {
            if(!controlKey.current && currentKey > 0) {
                loadCurrentSlide(currentIndex, currentKey - 1);
            }
            else if (currentIndex > 0) {
                const previousSlide = useSlideStore.getState().slides[currentIndex - 1];
                loadCurrentSlide(currentIndex - 1, previousSlide.contents.length - 1);
            }
        }
    };

    // Add event listeners
    useEffect(() => {
        if(process.env.REACT_APP_BROADCASTER === "true" || isOffline()) {
            window.addEventListener("keydown", handleKeyDown);
            window.addEventListener("keyup", handleKeyUp);
            // Remove event listeners on cleanup
            return () => {
                window.removeEventListener("keydown", handleKeyDown);
                window.removeEventListener("keyup", handleKeyUp);
            };
        }
    }, []); // Empty array ensures that effect is only run on mount and unmount
    
    return null;
};

export default KeyboardNavigation;