import { SlideContentType } from "./schemas";

export const contentTypeByMatchKey: Record<string, SlideContentType> = {
    "=== ": "title",
    "== ": "subtitle",
    "= ": "strong",
    "_ ": "emphasis",
    "\\^ ": "note",
    "\\+ ": "point",
    "<< ": "quote",
    "~ ": "image",
    "¤ ": "screenshare",
    "": "text" // order matters !
};