import { Vector3 } from "three";
import create, { State } from "zustand";

interface CameraState extends State {
    deltaPosition: Vector3;
    orientation: number;
    resetDeltaPosition: () => void;
}

const useCameraStore = create<CameraState>((set) => ({
    deltaPosition: new Vector3(0, 0, 0),
    orientation: 0,
    resetDeltaPosition: () => set({ deltaPosition: new Vector3(0, 0, 0)})
}))

export default useCameraStore;
