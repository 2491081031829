import { Vector3 } from "three";
import { DistanceFromObject } from "../components/Camera/constants";
import { FRAME_SIZE } from "../components/Frame/constants";
import { SlidePositioning } from "../components/Slide/schemas";
import { sumAngle } from "./getOrientationFromSlideTransition";

interface Arg1 {
    positioning: SlidePositioning,
    orientation: number
}

const getCameraRelativePositionAndRotation = ({ positioning, orientation}: Arg1) => {
    if(positioning === "layAbove") {
        return {
            position: new Vector3(0, DistanceFromObject, 0),
            rotation: new Vector3(-Math.PI/2, 0, orientation)
        };
    }
    if(positioning === "layBelow") {
        return {
            position: new Vector3(0, -DistanceFromObject, 0),
            rotation: new Vector3(Math.PI/2, 0, -sumAngle(orientation, Math.PI))
        };
    }
    if(positioning === "standAbove") {
        return {
            position: new Vector3(Math.sin(orientation)*DistanceFromObject, FRAME_SIZE / 2, Math.cos(orientation)*DistanceFromObject),
            rotation: new Vector3(0, orientation, 0)
        }
    }
    return {
        position: new Vector3(Math.sin(orientation)*DistanceFromObject, FRAME_SIZE / -2, Math.cos(orientation)*DistanceFromObject),
        rotation: new Vector3(0, orientation, Math.PI)
    }
}


export default getCameraRelativePositionAndRotation;