import React from "react";
import { SlideContent, SlideContentType } from "./schemas";
import Strong from "./Strong";
import Text from "./Text";
import Title from "./Title";
import Subtitle from "./Subtitle";
import Note from "./Note";
import Quote from "./Quote";
import Point from "./Point";
import Image from "./Image";
import ScreenShare from "./ScreenShare";

export interface Props {
    children: string;
    options: SlideContent["options"];
}

const componentByContentType: Record<SlideContentType, React.FunctionComponent<Props>> = {
    "title": Title,
    "subtitle": Subtitle,
    "strong": Strong,
    "emphasis": Text,
    "note": Note,
    "point": Point,
    "quote": Quote,
    "text": Text,
    "image": Image,
    "screenshare": ScreenShare
}


const renderContent = ({ type, text, options }: SlideContent, key: string) => {
    const Component = componentByContentType[type];

    if(!Component) {
        return null;
    }

    return React.createElement(Component, { key, options, children: text });
}

export default renderContent;