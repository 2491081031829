import { Slide, slideSchema } from "../../components/Slide/schemas";
import { DocumentSlide } from "../schemas";
import documentSlideContentsParser from "./documentSlideContentsParser";

const formatSlide = ({ mood, positioning, transition, contents}: DocumentSlide): Slide | null => {
    const slide = {
        mood,
        positioning,
        inTransition: typeof transition === "string" ? transition: transition[0],
        outTransition:  typeof transition === "string" ? transition: transition[1],
        contents: documentSlideContentsParser(contents)
    };

    try {
        return slideSchema.parse(slide);
    } catch (e) {
        console.error(String(e), slide)
        // Should not catch, parse here only helps with the union of slides schema variants (standing or laying)
        return null;
    }
}

export default formatSlide;