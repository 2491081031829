import { filter, map, pipe, prop } from "remeda";
import document from "./document.yml";
import { documentSchema } from "./schemas";
import formatSlide from "./helpers/formatSlide";
import { Slide, slideSchema } from "../components/Slide/schemas";

const isSlideValid = (slide: any) => slideSchema.check(slide);

const loadDocument = (): Slide[] => {
    try {
        return pipe(
            document,
            documentSchema.parse,
            prop('slides'),
            map(formatSlide),
            filter(isSlideValid)
        );
    }
    catch (e) {
        console.error(String(e));
        return [];
    }

    
}

export default loadDocument;