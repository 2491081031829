import React, { memo } from 'react';
import { Props } from './renderContent';
import VideoBox from '../VideoBox/VideoBox';
import { VideoTexture } from 'three';
import { useAsyncResource } from 'use-async-resource';
import moize from 'moize';


export const resolveScreenShareTexture = moize(() => 
    // @ts-ignore
    navigator.mediaDevices.getDisplayMedia({video: true, audio: false}).then((stream) => {
    const videoElement = document.createElement('video');
    videoElement.srcObject = stream;
    videoElement.play();
    return new VideoTexture(videoElement);
}), {isPromise: true});


const ScreenShare = ({ children, options }: Props) => {
    // @ts-ignore
    const [ getVideoTexture ] = useAsyncResource<VideoTexture>(resolveScreenShareTexture, []);

    const videoTexture = getVideoTexture();

    return React.createElement(VideoBox, { children: videoTexture, options });
}

export default memo(ScreenShare);