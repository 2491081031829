import { Vector3 } from "three";
import { GRID_SIZE, TEXTURE_REPEAT } from "../components/Grid/constants";
import { LayingTransition, SlidePositioning, StandingTransition } from "../components/Slide/schemas";
import { angleByTransition, simplifyAngle, belowEnum, invertedAngleByTransition } from "./getOrientationFromSlideTransition";

// must be a multiple of the pattern for the grid to look static
// during delta position reset
const motionVelocity = GRID_SIZE / TEXTURE_REPEAT * 15; 

const getDeltaPositionAccordingToSlideTransition = (positioning: SlidePositioning, outTransition: LayingTransition | StandingTransition, orientation: number, reverse: boolean) => {
    if(outTransition === "none") {
        return new Vector3(0, 0, 0);
    }
    const motionDirection = simplifyAngle(((belowEnum.check(positioning)?invertedAngleByTransition: angleByTransition)[outTransition] + orientation + (reverse ? 0 : Math.PI)));
    return new Vector3(Math.sin(motionDirection) * motionVelocity, 0, Math.cos(motionDirection) * motionVelocity)    
}

export default getDeltaPositionAccordingToSlideTransition;