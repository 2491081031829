import { SlideContent } from "../../components/Slide/schemas";

const contentParserFactory = (matchKey= "") => (content: string): Omit<SlideContent, 'type'> | null => {
    const regexp = new RegExp(`^${matchKey}(.*?)(?: \\[(.*)\\])?$`,'g');
    const result = regexp.exec(content);
    if(!result) {
        return null;
    }
    return {
        text: result[1] || "",
        options: result[2]?.split('|') || []
    };
}

export default contentParserFactory;