import * as z from "zod";
import { FRAME_WIDTH } from "../Frame/constants";

export const imageSizeEnum = z.enum(['full', 'half', 'third', 'quarter']);

export type ImageSize = z.infer<typeof imageSizeEnum>

export const IMAGE_SIZE: Record<ImageSize, number> = {
    full: FRAME_WIDTH,
    half: FRAME_WIDTH / 2,
    third: FRAME_WIDTH / 3,
    quarter: FRAME_WIDTH / 4
}