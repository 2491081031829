import * as z from "zod";
import { LayingTransition, slideLayingPositioningEnum, SlidePositioning, slideStandingPositioningEnum, StandingTransition } from "../components/Slide/schemas";

export const angleByTransition: Record<Exclude<LayingTransition | StandingTransition, "none">, number> = {
    "travLeft": Math.PI/2,
    "travUp": 0,
    "travForward": 0,
    "travRight": -Math.PI/2,
    "travDown": Math.PI,
    "travBackward": Math.PI,
}

export const invertedAngleByTransition: Record<Exclude<LayingTransition | StandingTransition, "none">, number> = {
    ...angleByTransition,
    "travLeft": -Math.PI/2,
    "travRight": Math.PI/2,
}

/*const aboveEnum = z.enum([
    slideLayingPositioningEnum.enum.layAbove,
    slideStandingPositioningEnum.enum.standAbove,
]);*/

export const belowEnum = z.enum([
    slideLayingPositioningEnum.enum.layBelow,
    slideStandingPositioningEnum.enum.standBelow,
]);

export const simplifyAngle = (angle: number) => {
    return ((angle + Math.PI*3) % (2*Math.PI)) - Math.PI;
}

export const sumAngle = (angle1: number, angle2: number) => {
    return simplifyAngle(angle1 + angle2);
}

const getOrientationFromSlidesTransition = (
    prevSlideOutTransition: LayingTransition | StandingTransition, 
    nextSlideInTransition: LayingTransition | StandingTransition,
    prevSlidePositioning: SlidePositioning,
    nextSlidePositioning: SlidePositioning,
    reverse?: boolean
    ) => {
    if(prevSlideOutTransition === "none" || nextSlideInTransition === "none") {
        return 0;
    }

    const prevAngle = (belowEnum.check(prevSlidePositioning) !== reverse ? invertedAngleByTransition: angleByTransition)[prevSlideOutTransition];
    const nextAngle = (belowEnum.check(nextSlidePositioning) !== reverse ? invertedAngleByTransition: angleByTransition)[nextSlideInTransition];

    const cumulatedAngles = reverse ? nextAngle - prevAngle : prevAngle - nextAngle;

    return simplifyAngle(cumulatedAngles);
}

export default getOrientationFromSlidesTransition;