import React, { Suspense, useMemo } from 'react';
import { useSpring } from "react-spring";
import { Flex } from '@react-three/flex';
import hash from "object-hash";
import useSlideStore from '../../hooks/useSlideStore';
import { FRAME_HEIGHT, FRAME_WIDTH } from './constants';
import { OpacityContext } from '../shared/contexts';
import { Euler, Vector3 } from 'three';
import { Slide } from '../Slide/schemas';
import renderContent from '../Slide/renderContent';

type EnhancedSlide = Slide & {
    index: number;
    position: Vector3;
    rotation: Euler;
}

interface Props {
    slide: EnhancedSlide;
}

const ContentVisibility = ({ visible, children }: { visible: boolean, children: React.ReactNode }) => {
    const { opacity } = useSpring({ 
        from: {
            opacity: 0
        },
        to: {
            opacity: visible ? 1 : 0
        }, 
        config: { duration: 1000 }});
    return (<OpacityContext.Provider value={opacity}>
        {children}
    </OpacityContext.Provider>);
}

const Frame = ({ slide }: Props) =>{
    const { currentIndex, currentKey } = useSlideStore(({ currentIndex, currentKey }) => ({ currentIndex, currentKey }));

    // this key force the frame to rerender when the slide contents changes
    // this prevents flex boxes to break their order if their size change
    const key = useMemo(() => hash(slide.contents), [slide]);

    return (
    <Suspense fallback={null}>
        <Flex key={key} marginLeft={FRAME_WIDTH} marginTop={FRAME_HEIGHT} size={[FRAME_WIDTH, FRAME_HEIGHT, 0]} position={slide.position} rotation={slide.rotation} justifyContent="center" alignItems="center" flexDirection="column">
            {slide.contents.map((subContents, contentIndex) => {
                const isContentVisible = slide.index === currentIndex && contentIndex <= currentKey;
                return (<ContentVisibility key={contentIndex} visible={isContentVisible}>
                        {subContents.map((subContent, subContentIndex) => {
                            const key = `${slide.index}-${contentIndex}-${subContentIndex}`;
                            return renderContent(subContent, key);
                        })}
                </ContentVisibility>)
            })}
        </Flex>
    </Suspense>
    );
}

export default Frame;