import { FRAME_WIDTH } from "../components/Frame/constants";

type TextRef = {
    geometry: {
        boundingBox: {
            min: {
                x: number;
                y: number;
            }
            max: {
                x: number;
                y: number;
            }
        }
    }
} | null;

const getBoxHeightFromText = (textRef: TextRef) => {
    if(!textRef) {
        return { height: 0, width: 0 };
    }
    const height = (textRef.geometry.boundingBox.max.y - textRef.geometry.boundingBox.min.y) * FRAME_WIDTH;
    const width = (textRef.geometry.boundingBox.max.x - textRef.geometry.boundingBox.min.x) * FRAME_WIDTH;

    return { height, width };
}

export default getBoxHeightFromText;