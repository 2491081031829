import React, { useMemo } from 'react';
import useSlideStore from "../../hooks/useSlideStore"
import styles from "./SlideNumber.module.css";

const SlideNumber = () => {
    const { currentIndex } = useSlideStore(({ currentIndex }) => ({ currentIndex }));

    const currentSlideNumber = useMemo(() => currentIndex + 1, [currentIndex]);

    if(currentIndex<0) {
        return null;
    }
    return <div className={styles.SlideNumber}>{currentSlideNumber}</div>
}

export default SlideNumber;