import React, { memo, useContext } from 'react';
import { valid as isValidColor } from "chroma-js";
import { Box } from '@react-three/flex';
import { animated } from 'react-spring/three';
import { Text } from '@react-three/drei';
import emojiRegex from "emoji-regex/RGI_Emoji";
import { Text as TroikaText} from 'troika-three-text'
import { useAsyncResource } from 'use-async-resource';
import getBoxSizeFromText from '../../helpers/getBoxHeightFromText';
import { FRAME_WIDTH } from '../Frame/constants';
import { OpacityContext } from '../shared/contexts';
import { SlideContent } from '../Slide/schemas';
import getAlignmentRulesFromOptions from '../../document/helpers/getAlignmentRulesFromOptions';
import { FONT_SIZE, TEXT_VERTICAL_MARGIN_FACTOR } from './constants';
import Emoji from '../Emoji/Emoji';
import getThreeColor from '../../helpers/getThreeColor';


/*
Prerender the text outside the scene in order to set the box size on the first render.
This is mandatory as the box order (with its siblings) becomes wrong when updating the box after the first render.
The first render is delayed using react suspense API.
*/
const resolveBoxSize = ({ text, font, fontSize }: {
    text: string;
    font: string;
    fontSize: number;
}) => new Promise<{
    width: number;
    height: number;
}>((resolve) => {
    const t = new TroikaText();
    t.font = font;
    t.text = text;
    t.fontSize = fontSize;
    t.maxWidth = 1;

    t.sync(() => {
        resolve(getBoxSizeFromText(t));
    })
})


const AnimatedText = animated(Text);

interface Props {
    options: SlideContent["options"];
    children: string;
    bold?: boolean;
    size?: number;
}

const TextBox = ({
    options,
    children,
    bold,
    size
}: Props) => {
    const opacity = useContext(OpacityContext)

    let font = "https://fonts.cdnfonts.com/s/16219/Gilroy-Light.woff";

    if(bold) {
        font = "https://fonts.cdnfonts.com/s/16219/Gilroy-Heavy.woff";
    }

    const color = options.find(option => isValidColor(option));

    const alignmentRules = getAlignmentRulesFromOptions(options);
    const emoji = options.find(option => emojiRegex().test(option));
    const fontSize = size || FONT_SIZE.DEFAULT

    const [getBoxSize] = useAsyncResource(resolveBoxSize, { text: children, font, fontSize })
    const boxSize = getBoxSize();

    return (<Box width={FRAME_WIDTH} height={boxSize.height} marginTop={fontSize * TEXT_VERTICAL_MARGIN_FACTOR} marginBottom={fontSize * TEXT_VERTICAL_MARGIN_FACTOR} justifyContent={alignmentRules.boxAlign} flexDirection="row" flexWrap="no-wrap">
        <Box width={boxSize.width} height={boxSize.height} centerAnchor>
            <AnimatedText maxWidth={1} fillOpacity={opacity} scale={FRAME_WIDTH} fontSize={fontSize} font={font} textAlign={alignmentRules.textAlign} color={getThreeColor(color)}>{children}</AnimatedText>
        </Box>
        {emoji && <Emoji fontSize={size || FONT_SIZE.DEFAULT}>{emoji}</Emoji>}
    </Box>);
}

export default memo(TextBox);