import React, { useContext } from 'react';
import { Box } from '@react-three/flex';
import { animated } from 'react-spring/three';
import { Plane, useTexture } from '@react-three/drei';
import { DoubleSide, sRGBEncoding } from 'three';
import { Notomoji } from '@svgmoji/noto';
import data from 'svgmoji/emoji.json';
import { SpriteCollection } from '@svgmoji/core';
import { OpacityContext } from '../shared/contexts';
import { EMOJI_FONT_SIZE_FACTOR, EMOJI_RESOLUTION } from './contants';

interface EmojiProps {
    children: string;
    fontSize: number;
}

const notomoji = new Notomoji({ data, type: SpriteCollection.Individual });

const Emoji = ({ children, fontSize }: EmojiProps) => {
    const url = notomoji.url(children)
    const texture = useTexture(url);
    texture.image.width = EMOJI_RESOLUTION;
    texture.image.height = EMOJI_RESOLUTION;
    texture.encoding = sRGBEncoding;
    const opacity = useContext(OpacityContext);

    const size = fontSize * EMOJI_FONT_SIZE_FACTOR;

    return <Box width={size} height={size} centerAnchor marginLeft={size / 4} marginTop={size / -6} marginRight={size / 4} >
        <Plane args={[size, size]}>
            <animated.meshBasicMaterial map={texture} side={DoubleSide} opacity={opacity} transparent></animated.meshBasicMaterial>
        </Plane>
    </Box>
}

export default Emoji;