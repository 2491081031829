import { pipe, reduce, toPairs } from "remeda";
import { contentTypeByMatchKey } from "../../components/Slide/constants";
import { SlideContent } from "../../components/Slide/schemas";
import contentParserFactory from "./documentSlideContentParserFactory"


const documentSlideContentParser = (content: string) => pipe(
        contentTypeByMatchKey,
        toPairs,
        reduce((acc: SlideContent | null, [matchKey, type]) => {
            const parsedContent = contentParserFactory(matchKey)(content)
            if(acc !== null || parsedContent === null){
                return acc;
            }
            return {
                ...parsedContent,
                type
            } as SlideContent
        }, null)
    );

export default documentSlideContentParser;