import React, { memo, useContext } from 'react';
import { animated } from 'react-spring/three';
import { Box } from '@react-three/flex';
import { Plane, useTexture } from '@react-three/drei';
import { FRAME_SIZE, FRAME_WIDTH } from '../Frame/constants';
import { DoubleSide, sRGBEncoding } from 'three';
import { OpacityContext } from '../shared/contexts';
import { ImageSize, IMAGE_SIZE, imageSizeEnum } from './constants';
import { SlideContent } from '../Slide/schemas';
import getAlignmentRulesFromOptions from '../../document/helpers/getAlignmentRulesFromOptions';

interface Props {
    children: string;
    options: SlideContent["options"];
}

const ImageBox = ({ children: url, options }: Props) => {
    const texture = useTexture(url);
    texture.encoding = sRGBEncoding;
    const opacity = useContext(OpacityContext)

    const sizeOption: ImageSize = options.find((option) => imageSizeEnum.check(option)) as ImageSize || "half";
    const size = IMAGE_SIZE[sizeOption];
    const width = Math.min(texture.image.width/texture.image.height, 1) * size;
    const height = Math.min(texture.image.height/texture.image.width, 1) * size;
    const alignmentRules = getAlignmentRulesFromOptions(options);

    return (<Box width={FRAME_WIDTH} height={height} marginTop={FRAME_SIZE/40} marginBottom={FRAME_SIZE/40} justifyContent={alignmentRules.boxAlign} flexDirection="row" flexWrap="no-wrap">
        <Box width={width} height={height} centerAnchor>
            <Plane args={[width, height]}>
                <animated.meshBasicMaterial map={texture} side={DoubleSide} opacity={opacity} transparent></animated.meshBasicMaterial>
            </Plane>
        </Box>
    </Box>)
};

export default memo(ImageBox);