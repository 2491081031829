import { filter, map, pipe } from "remeda";
import { SlideContent, slideContentSchema } from "../../components/Slide/schemas";
import { DocumentSlide } from "../schemas";
import contentParser from "./documentSlideContentParser";

const isContentValid = (content: any) => slideContentSchema.check(content); // somehow the check function cannot be used in the filter directly

const documentSlideContentsParser = (contents: DocumentSlide["contents"]) => {
    const probablyMultiLineContents = typeof contents === "string" ? [ contents ] : contents;

    return probablyMultiLineContents.reduce((acc: SlideContent[][], probablyMultiLineContent) => { 
        return [
            ...acc,
            pipe(
                probablyMultiLineContent.split("\n"),
                filter(c => c !== ""),
                map(contentParser),
                filter(isContentValid)
            )
        ];
    }, [])
}

export default documentSlideContentsParser;